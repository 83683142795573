@keyframes imageAnimation {
    0% {
      transform: translateY(0);
      opacity: 0.5;
    }
    50% {
      transform: translateY(20px); 
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 0.5;
    }
  }
  
  .innerLoader img:nth-child(1) {
    animation: imageAnimation 2s infinite ease-in-out 0s; 
  }
  
  .innerLoader img:nth-child(2) {
    animation: imageAnimation 2s infinite ease-in-out 1s; 
  }
  
  .innerLoader img:nth-child(3) {
    animation: imageAnimation 2s infinite ease-in-out 4s; 
  }
  
  
  .abcs {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .mainLoader {
    position: relative;
  }
  
  .innerLoader {
    margin-left: 6px;
    margin-bottom: 17px;
    display: flex;
    height: 20px;
  }
  
  .innerLoader img {
    animation: imageAnimation 2s infinite ease-in-out; 
  }
  
  .mainPartLoader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mainPartLoader img{
    height: 40px;
    width: 30px;
  }

  