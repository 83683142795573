body {
    background: radial-gradient(50% 50% at 50% 50%, #C67B51 0%, rgba(198, 123, 81, 0.00) 100%), #A04323;
    overflow-x: hidden;
}

a {
    color: #f2f2f2 !important;

}

.banner-text {
    width: 650px;
    margin: 63px auto !important;
}

.rightSideGetFranchise {
    position: absolute;
    top: 17vw;
    left: 0;
    height: 200px;
}

.rightSideGetFranchises {
    position: absolute;
    top: 17vw;
    right: 0;
    height: 200px;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background: #3A150C;
    padding: 0 100px;
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.nav__brand {
    text-transform: uppercase;
}

.nav__menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 3rem;
    margin-top: .7rem;
}

.headerButton {
    position: absolute;
    color: #78321C;
    font-family: 'Poppins';
    right: 125px;
    margin-top: -21px;
    border-radius: 10px;
    padding: 10px 20px;
    letter-spacing: 2px;
    text-decoration: none;
    box-shadow: 0 20px 50px rgba(255, 255, 255, 0.05);
    overflow: hidden;

}

.headerButton:before {
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    content: '';
    width: 50%;
    background: rgba(255, 255, 255, 0.05);
}

.headerButton span:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, #a04323, #C67B51);
    animation: animate1 2s linear infinite;
}

@keyframes animate1 {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.headerButton span:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(to bottom, #a04323, #C67B51);
    animation: animate2 2s linear infinite;
    animation-delay: 1s;
}

@keyframes animate2 {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(100%);
    }
}

.headerButton span:nth-child(3) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(to left, #a04323, #C67B51);
    animation: animate3 2s linear infinite;
}

@keyframes animate3 {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.headerButton span:nth-child(4) {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(to top, #a04323, #C67B51);
    animation: animate4 2s linear infinite;
    animation-delay: 1s;
}

@keyframes animate4 {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(-100%);
    }
}

.nav__toggler {
    display: none;
}

.nav__toggler div {
    width: 2.5rem;
    height: 0.2rem;
    margin: 0.4rem;
    background: rgb(204, 204, 204);
    transition: 0.3s ease-in;
}

@media screen and (max-width: 768px) {
    .nav {
        padding: 0 30px;
    }

    .nav__toggler {
        display: block;
        cursor: pointer;
    }

    .nav__menu {
        position: fixed;
        top: 0 !important;
        left: -100%;
        height: 100vh;
        width: 100vw;
        background: #3A150C;
        flex-direction: column;
        transition: 0.5s ease-in;
        text-decoration: none;
        list-style: none;
    }

    .nav__menu.nav__active {
        left: 0;
        margin-top: 47px;
    }

    .nav__item {
        margin: 0 !important;
        padding: 0 !important;
        text-decoration: none;
        list-style: none;
    }

    .nav__link {
        color: #fff;
        text-decoration: none;
        font-size: 18px;
        font-family: serif;
        font-weight: 100;
        padding: 0px;
        display: block;
    }

    .navLink {
        margin-top: -25px;
        left: 30px;
        position: absolute;
    }

    .headerButton {
        padding: 10px;
        text-align: center;
        margin-top: -30px;
    }

    .navLink1 {
        margin-top: -240px;
        left: 30px;
        position: absolute;
    }

    .navLink2 {
        left: 30px;
        position: absolute;
    }
}

ul {
    list-style: none;
    text-decoration: none;
}

li {
    text-decoration: none;
    list-style: none;
    font-family: 'Poppins';
    color: #fff;
    text-decoration: none;
    display: block;
}

.nav__active {
    transform: translateX(0%);
}

.toggle .line1 {
    transform: rotate(-45deg) translate(-4px, 5px);
}

.toggle .line2 {
    opacity: 0;
}

.toggle .line3 {
    transform: rotate(45deg) translate(-4px, -5px);
}




/* banner  */

.banner-main {
    padding: 80px 0;
}

.banner-text {
    width: 650px;
    margin: 0 auto;
}

.banner-text h2 {
    font-family: 'Poppins';
    font-size: 36px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: 0;
    text-align: center;
    color: #FFFFFF;
}

.banner-text h2 span {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0;
    text-align: center;
    color: #FFAB83;
}

.banner-button {
    text-align: center;
    width: 215px;
    margin: 0px auto 0;
    background-color: #D67848;
    padding: 15px;
    border-radius: 15px;
}

.banner-button a {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
    text-transform: uppercase;
}


/* homeabout  */
.home-about {
    background: rgba(58, 21, 12, 0.40);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    position: relative;
    padding: 65px;
    border-radius: 35px;
    padding-right: 0px;
}

.about {
    max-width: 104%;
    position: absolute;
    height: 350px;
    bottom: 0;
    right: 0;
    border-radius: 35px;
}

.about-home-text h2 {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0;
    text-align: left;
    color: #FFAB83;
}

.about-home-text h3 {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0;
    text-align: left;
    color: #FFFFFF;
}

.about-home-text p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0;
    text-align: left;
    color: #FFFFFF;
    padding: 30px 0 0;
}

/* operational cafe  */
.operational-cafe-main {
    padding: 0;
}

.operational-cafe h2 {
    font-family: Poppins;
    font-size: 38px;
    font-weight: 600;
    line-height: 57px;
    letter-spacing: 0;
    text-align: left;
    color: #FFFFFF;
}

.operational-cafe p {
    font-family: Poppins;
    font-size: 19px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0;
    text-align: left;
    color: #FFFFFF;
    padding-top: 70px;
    padding-bottom: 70px;
}

.operational-cafe p span {
    font-family: Poppins;
    font-size: 19px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0;
    text-align: left;
    color: #FFAB83;
}

/* mission box   */
.missionbox h3 {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0;
    text-align: center;
    color: #FFFFFF;
}

.missionbox h3 span {
    color: #EFB290;
}

.missionbox {
    background-color: #78321C;
    padding: 47px 30px;
    border-radius: 20px;
    min-height: 372px;
}

.missionbox p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
    color: #FFFFFF;
}

.mission-inner {
    min-height: 140px;
}

.mission-bg {
    background-image: url('../Images/missionbg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0;
}

.people-love h2 {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: 0;
    text-align: center;
    color: #FFFFFF;
}

.people-love {
    width: 70%;
    margin: 60px auto;
}

.people-love h3 {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0;
    text-align: center;
    color: #FFAB83;
}

.people-love p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0;
    text-align: center;
    color: #FFFFFF;
}

.people-love p span {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0;
    text-align: center;
    color: #FFAB83;
}

/* girl box  */

.girlbox-margin {
    margin-top: 80px;
}


/* footer  */
.footer-main {
    background-color: #3A150C;
    padding: 3px 0;
}

.footer-form {
    min-height: 74px;
}

.footer-form input {
    background-color: #61443D;
    padding: 10px 20px;
    border: none;
    outline: none;
    width: 100%;
    border-radius: 10px;
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
}

.footer-form input::placeholder {
    color: #8a7873;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.footer-text h3 {
    text-decoration: none;
    font-family: Poppins;
    padding-top: 5px;
    font-size: 20px;
    font-weight: 700;
    float: left;
    color: #fff;
    margin: 0;
    cursor: pointer;
}

.franchise-nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 91%;
    margin-bottom: 3rem;

}

.franchise-nav a h2 {
    text-decoration: none;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    margin-right: 20px;
    padding: 15px;
    border-radius: 30px;
    border: 1px solid #fff;
}

.franchise-nav a {
    text-decoration: none;
}

.franchise-nav a h2:hover {
    color: #FFAB83;
    font-weight: 700;
    border: 1px solid #FFAB83;
}

.footer-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 30px auto;
}

.footer-nav a h2 {
    text-decoration: none;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    color: #f2f2f2;
}

.footer-nav a {
    text-decoration: none;
}

.footer-nav a h2:hover {
    color: #FFAB83;
    font-weight: 700;
}


.footer-social {
    padding-bottom: 50px;
}

.footer-social {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.footer-social i {
    color: #000;
    font-size: 24px;
    background-color: #FFAB83;
    padding: 8px 13px;
    width: 65%;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 13px;
}

.footer-border-right {
    border-right: 3px solid #61443D;
}

.footer-copyright {
    padding: 20px 0;
}

.footer-copyright h3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: center;
    color: #ffffff;
}

.footer-copyright h3 span {
    color: #ffffff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    text-align: center;
}

.border1 {
    border: 1px solid #61443D;
}


/* about page  */
.abt-banner-text h2 {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    margin-top: 60px
}

.abt-banner-text h3 {
    color: #FFAB83;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.abt-banner-text p {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 30px 0;
    margin: 0;
}

.about-main {
    padding: 80px 0 55px;
}

.outlet-years {
    padding: 50px 0 0;
}

.offer-bg {
    background-image: url('../Images/missionbg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 55px 0;
}

.abt-offer-text h2 {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    margin: 0;
}

.abt-offer-text h3 {
    color: #FFAB83;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.abt-offer-text p {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 1013px;
    padding: 30px 0;
    margin: 0 auto;
}


.abt-menu-box h2 {
    color: #f2f2f2;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.features-main {
    padding: 55px 0;
}

.abt-features h2 {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
}

.abt-features h3 {
    color: #FFAB83;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
}

.abt-features p {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 1013px;
    margin: 30px auto;
}

.feature-box {
    background-color: #78321C;
    padding: 45px 27px;
    border-radius: 15px;
    text-align: center;
}

.feature-box-text h3 {
    text-transform: uppercase;
    padding: 10px 0;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;

}

.abt-feature-row {
    padding: 40px 0;
}


/* career  */

.career-main {
    padding: 80px 0;
}

.career-text h2 {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 60px;
}

.career-text h3 {
    color: #FFAB83;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.career-text p {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 40px 0;
}


/* franchise  */

.franchisee-banner {
    padding: 100px 0 60px;
}

.container-new {
    width: 92%;
    margin: 0 0 0 auto;
}

.franchise-text h2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: bold;
    width: 658px;
    text-align: left;
}

.franchise-text h2 p {
    color: #FFAB83;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: bold;
    margin-top: .5rem;
    margin-bottom: 3rem;
    width: 658px;
    text-align: left;
}

.franchise-text p {
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 508px;
    text-align: left;
}

.franchise-text span {
    color: #FFAB83;
    font-family: Poppins;
    font-size: 20px;
    float: left;
    margin-top: -1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.franchise-button {
    padding: 40px 0;
    text-align: left;
    margin-top: 3rem;
}

.franchise-button a {
    background: #D67848;
    border-radius: 20px;
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    padding: 13px 18px;
    text-decoration: none;
    text-transform: uppercase;
}

.container-fluid {
    position: relative;

    z-index: 2;
}

.silverModel {
    max-width: 100%;
}

.abc {
    position: absolute;
    top: 60vw;
    z-index: 1;
}

.rightSide {
    position: absolute;
    top: 60vw;
    right: 0px;

    z-index: 1;
}


.franchise-bg {
    background-image: url('../Images/missionbg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;

}

.franchise-box {
    background-color: #78321C;
    text-align: center;
    padding: 50px;
    border-radius: 30px;
    margin-left: 0rem;
    margin-bottom: 80px;
    min-height: 30vh;
}

.franchise-box1 {
    background-color: #E3996F;
    text-align: center;
    padding: 20px;
    margin-top: 2rem;
    border-radius: 30px;
    min-height: 350px;
}

.franchise-box11 {
    background: rgba(227, 153, 111, 0.30);
    text-align: center;
    padding: 20px;
    margin-top: 2rem;
    border-radius: 30px;
    min-height: 240px;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.franchise-box112 {
    border-radius: 30px;
    min-height: 240px;
    background-repeat: no-repeat;
    position: relative;
    max-width: 100%;
    display: flex;
    /* margin-bottom: 30px; */
    justify-content: center;
    align-items: center;
}

.outlets {
    width: 300px;
    padding-left: 9px;
}

.franchise-boxModel {
    background: rgba(227, 153, 111, 0.30);
    text-align: center;
    padding: 20px;
    margin-top: 2rem;
    border-radius: 30px;
    min-height: 580px;
    background-repeat: no-repeat;
}

.franchise-boxModel h2 {
    color: #FFB08B;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 22px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
}


.applicationFormProcess {
    position: absolute;
    top: 20%;
}

.applicationForm1 {
    position: absolute;
    bottom: 20%;
}

.applicationForm {
    position: absolute;
    bottom: -10%;
    right: 10%;
}

.franchise-box111 img {

    width: 250px;
    min-height: 200px;
    margin-top: 100%;
}

.franchise-box img {
    margin-bottom: 30px;
}

.franchise-box p {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.franchise-box p span {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.journey-text h2 {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.journey-text h3 {
    color: #FFAB83;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.journey-box p {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.journey-box p span {
    color: #FFAB83;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


/* slider  */


.slick-slider {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}


.slick-prev {
    font-size: 0;
    line-height: 0;
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    left: -45px !important;
}

.slick-next {
    width: 50px;
    right: 0;
    position: absolute;
    height: 50px;
    z-index: 1;
    border-radius: 50%;
}


.slick-prev:before {
    /* font-family: 'slick'; */
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: url('../Images/slickarrowleft.png') !important;
}

.slick-next:before {
    /* font-family: 'slick'; */
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: url('../Images/slickarrowright.png') !important;
}

.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
    padding-bottom: 40px !important;
}

.center .slick-center h2 {
    transform: scale(1.08);
}

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.slick-slide {
    display: flex !important;
    justify-content: center !important;
}


/* success stories  */
.success-stories-main {
    padding: 70px 0;
}

.success-stories {
    background-color: #78321C;
    width: 250px;
    padding: 30px;
    border-radius: 30px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
}

.success-stories h2 {
    color: #FFAB83;
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 99.5%;
    /* 35.82px */
}

.success-stories h3 {
    color: #FFF;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 99.5%;
    text-align: center;
}

.testimonial-main {
    padding: 40px 0;
}

.testimonial p {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.testimonial h2 {
    color: #FFAB83;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.testimonial h3 {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.testimonial-block {
    position: relative;
}

.upper-quote {
    position: absolute;
    top: 0;
    z-index: -1;
    opacity: 0.2;
    left: 50px;
}

.lower-quote {
    position: absolute;
    /* top: 0; */
    z-index: -1;
    opacity: 0.2;
    right: 40px;
    bottom: -20px;
}


/* form  */
.franchise-form-main {
    padding: 80px 0;
}

.form-inner label {
    margin-bottom: 10px;
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 99.5%;
}

.form-inner label span {
    color: #FFAB83;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 99.5%;
    /* 19.9px */
}

.form-inner input {
    background-color: #61443D;
    padding: 10px 20px;
    border: none;
    outline: none;
    width: 100%;
    border-radius: 10px;
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
}

.form-inner input::placeholder {
    color: #8a7873;
}

.form-inner-radio h2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 99.5%;
    margin: 0;
    padding-right: 10px;
}

.form-inner-radio h2 span {
    color: #FFAB83;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 99.5%;
    /* 19.9px */
}

.form-radio-bg {
    background-color: #3A150C;
    padding: 15px;
    border-radius: 15px;
    width: 188px;
    display: flex;
    justify-content: center;
}

.form-inner-radio label {
    margin-bottom: 10px;
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 99.5%;
    margin: 0px;
}

.submit-button button {
    background-color: #3A150C;
    border: 1px solid #fff;
    padding: 10px 20px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 10px;
    font-family: Poppins;
    font-size: 20px;
}

.submit-button button:hover {
    background-color: #C67B51;
    color: #000;
}

/* privacy policy  */

.privacy-policy-main {
    padding: 80px 0px;
}

.privacy-policy-text {
    padding-bottom: 30px;
}

.privacy-policy-text h2 {
    color: #FFAB83;
    font-family: Poppins;
    text-align: left;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 99.5%;
    margin-bottom: 15px;
}

.privacy-policy-text p {
    text-decoration: none;
    font-family: Poppins;
    text-align: justify;
    font-size: 16px;
    font-weight: 400;
    color: #f2f2f2;
}

.privacy-policy {
    text-decoration: none;
    font-family: Poppins;
    text-align: justify;
    font-size: 16px;
    font-weight: 400;
    color: #f2f2f2;
}

/* admin portal  */
.card {
    background-color: #a04323;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 20px #0000001a;
    padding: 20px;
    margin-top: 100px;
    width: 400px;
}

.card p {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2px;
    text-align: center;
    margin-bottom: 3rem;
    color: #FFAB83;
}

.form input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #FFAB83;
    background-color: #a04323;
    border-radius: 4px;
    color: #fff;
    font-size: 1rem;
    box-sizing: border-box;
}

.switch-role {
    background-color: transparent;
    padding: 10px;
    border: none;
    color: white;
}

.input-group-addon {
    position: absolute;
    top: 56.1%;
    right: 21px;
    width: 11%;
    border-radius: 4px;
    height: 49.5px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #FFAB83;
    border: 1px solid #FFAB83;
    color: #fff;
}

.form input:hover {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    background-color: #a04323;
    color: #FFAB83;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;
}

select {
    width: 100%;
    padding: 12px;
    margin-bottom: 0px;
    border: 1px solid #FFAB83;
    background: #61443D;
    border-radius: 4px;
    color: #fff;
    font-size: 1rem;
    box-sizing: border-box;
}

.button-design {
    width: 25%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    background-color: #FFAB83;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.button-design:hover {
    background-color: #d9534f;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.forgot-password {
    color: #f2f2f2;
    text-decoration: none;
    font-size: 17px;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}

.forgot-password:hover {
    color: #FFAB83;
}


.course_card {
    margin: 25px 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #a04323;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    background-clip: border-box;
    transition: 0.25s ease-in-out;
}

.course_card_img {
    max-height: 100%;
    max-width: 100%;
}

.course_card_img img {
    height: 250px;
    width: 100%;
    transition: 0.25s all;
}

.course_card_img img:hover {
    transform: translateY(-3%);
}

.course_card_content {
    padding: 16px;
}

.course_title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 10px;
}



.course_card:hover {
    transform: scale(1.025);
    border-radius: 30px;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.25);
}

.fullScreenOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
}

.fullScreenContent {
    width: 100%;
    max-width: 100vw;
    max-height: 80vh;
    padding: 20px;
    background-color: #fff;
    color: #000;
    border-radius: 8px;
    box-sizing: border-box;
    text-align: justify;
    overflow: auto;
}

.fullScreenContent p {
    text-align: left;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 18px;
}

.fullScreenHeader {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
}

.closeButton {
    cursor: pointer;
    font-size: 24px;
    color: #333;
}

/* FAQs */
.faq {
    position: relative;
    top: 20vh;
    height: 1300px;
}

.FAQs-text h2 {
    color: #FFAB83;
    font-family: Poppins;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 99.5%;
    /* margin-top: 3rem; */
    margin-bottom: 3rem
}

.faq .faq-list {
    padding: 0 100px;
}

.faq .faq-list ul {
    padding: 0;
    list-style: none;
}

.faq .faq-list li+li {
    margin-top: 15px;
}

.faq .faq-list li {
    padding: 20px;
    background: #78321C;
    border-radius: 4px;
    position: relative;
}

.fa-sm {
    margin-top: 10px;
}

.faq .faq-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 30px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
}

.faq .faq-list .icon-help {
    font-size: 24px;
    position: absolute;
    left: 0;
    margin-left: 20px;
    color: #FFAB83;
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
}

.faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
    color: #FFAB83;
    text-align: justify;
}

.faq .faq-list .icon-show {
    display: none;
}

.faq .faq-list a.collapsed {
    color: #fff;
    transition: 0.3s;
}

.faq .faq-list a.collapsed:hover {
    color: #FFAB83;
}

.faq .faq-list a.collapsed .icon-show {
    display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
    display: none;
}

@media (max-width: 1200px) {
    .faq .faq-list {
        padding: 0;
    }
}

.butGetSide {
    position: absolute;
    right: 0;
}

.franchise-box1111 {
    background: rgba(227, 153, 111, 0.30);
    text-align: center;
    padding: 20px;
    margin-top: 2rem;
    border-radius: 30px;
    min-height: 350px;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.TestimonialsCups {
    height: 200px;
}

.franchise-box1111 img {
   background-repeat: no-repeat;
   border-radius: 10px;
   height: 170px;
   width: 170px;
   border-radius: 100%;
}

.TestimonialsProcess{
    font-size: 25px;
    font-family: 'Poppins';
    font-weight: 200;
    color: white;
    margin-top: -10px;
}
.franchiseChai{
    height: auto;
    width: 270px;
    margin-top: 35px;
}
.blogs {
    position: relative;
    top: 120px;
    min-height: 1056px;
}

.fullScreenOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #3A150C;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.fullScreenContent {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 100vw;
    width: 100%;
    overflow-y: auto;
}

.fullScreenHeader {
    display: flex;
    justify-content: flex-end;
    font-size: 24px;
    cursor: pointer;
    color: #3A150C;
    padding: 10px;
}

.fullScreenHeader span {
    cursor: pointer;
}



.menuList {
    background: transparent;
    position: relative;
    min-height: 500px;
    width: 100%;
    margin-top: 70px;
    display: flex;
    flex-wrap: wrap;
}

.menuListInner {
    margin: 25px;
    flex: 1;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuListInner:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}

.cardContent img {
    border-radius: 20px;
}

.menulistbottom {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: 40px;
    background-color: red;
    border-radius: 20px;
    border: 1px solid gray;
    margin-top: 5px;
    padding: 0 10px;
    /* Adjust padding as needed */
}

.menuText {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    margin: 14px;
}

.menulistbottom i {
    color: white;
    font-size: 20px;
    margin: 14px;
}

.sideImage {
    float: right;
    height: 30px;
}

.fullScreenView {
    margin-bottom: 60px;
}



.popup {
    height: 70px;
    background-color: white;
    width: 70px;
    z-index: 10;
    opacity: 1;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    right: 40px !important;
    bottom: 15px;
}

.notification {
    background-color: transparent;
    border: 1px solid gray;
    border-radius: 10px;
    width: 150px;
    height: 50px;
    margin-left: -40px;
    padding-top: 7px;
    margin-top: -60px;
    transform: translateX(-50%);
    font-weight: 700;
    color: white;
    font-size: 20px;
    position: absolute;
    top: 10px;
    left: 50%;
}

.popup-content {
    text-align: center;
    position: relative;
}

.popup-content img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-bottom: 0px;
    margin-top: 7px;
    margin-right: 0px;
}

.close-btn {
    position: absolute;
    top: -20px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: white;
}

.popup-animation {
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
    margin: 20px 0;
    padding: 10px;
    min-height: 200px;
    color: #fff;
    border-radius: 25px;
  }
  
  .box img {
    width: 100%; 
    max-height: 100%; 
    border-radius: 25px;
  }
  