@media screen and (max-width: 576px) {
    .popup {
        height: 40px;
        background-color: white;
        width: 40px;
        border-radius: 50%;
        position: absolute;
        cursor: pointer;
        right: 10px !important;
        ;
        bottom: 95px;
    }

    .notification {
        background-color: transparent;
        border: 1px solid gray;
        border-radius: 10px;
        width: 100px;
        height: 30px;
        margin-left: -30px !important;
        padding-top: 3px;
        margin-top: -60px;
        transform: translateX(-50%);
        font-weight: 700;
        color: white;
        font-size: 14px;
        position: absolute;
        top: 30px;
        left: 50%;
    }

    .popup-content {
        text-align: center;
        position: relative;
    }

    .popup-content img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-bottom: 0px;
        margin-top: 7px;
        margin-right: 2px;
    }

    .banner-text {
        width: 650px;
        margin: 63px auto;
    }

    .home-about {
        padding: 0px;
        min-height: 720px;
    }

    .banner-main {
        padding: 40px 12px;
    }

    .franchise-box {
        min-width: 250px !important;
        margin-left: -2px;
        padding: 50px 15px 50px 15px !important;
    }

    .banner-text h2 {
        font-size: 21px;
        line-height: 34px;
    }

    .rightSideGetFranchise {
        height: 100px;
        position: absolute;
        top: 27vh;
    }
    .rightSideGetFranchises {
        height: 100px;
        position: absolute;
        top: 27vh;
    }
    .about{
        max-width: 106%;
    position: absolute;
    bottom: -2px;
    right: -7px;
    height: auto;
    border-radius: 35px;
    }

    .banner-text h2 span {
        font-size: 24px;
        line-height: 39px;
    }

    .silverModel {
        max-width: 100%;
    }

    .about-home-text h2 {
        font-size: 34px !important;
        padding: 30px 8px;
    }

    .about-home-text h3 {
        font-size: 24px;
        padding: 0 8px;
        margin-top: -32px;
    }

    .about-home-text p {
        font-size: 18px;
        line-height: 28px;
        padding: 18px 8px;
    }

    .career-main {
        padding: 79px 25px;
    }

    .img-fluid {
        padding: 0 8px;
    }

    .operational-cafe h2 {
        font-size: 32px;
    }

    .operational-cafe p {
        padding: 15px 12px;
        font-size: 17px;
    }

    .operational-cafe-main {
        padding: 40px 12px;
    }

    .missionbox {
        min-height: auto;
    }

    .mission-bg {
        padding: 40px 12px;
    }

    .missionbox {
        padding: 30px;
    }

    .people-love {
        width: 80%;
        margin: 40px auto;
    }

    .people-love h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .people-love h3 {
        font-size: 32px;
        line-height: 40px;
    }

    .people-love p {
        font-size: 17px;
        line-height: 30px;
    }

    .people-love p span {
        font-size: 17px;
    }

    .girlbox-margin {
        margin-top: 0;
    }

    .footer-form input {
        margin-bottom: 20px;
    }

    .footer-text h3 {
        margin-left: -1rem !important;
    }

    .footer-nav {
        display: inline;
        width: 95%;
        margin: 40px auto;
    }

    .franchise-nav {
        display: inline;
        width: 100%;
        margin: 40px auto;
    }

    .franchise-nav a h2 {
        margin-right: 1rem;
        width: 100%;
    }

    .navbar-bg {
        padding: 12px 12px;
    }

    .navbar-main {
        margin-top: 10px;
    }

    .banner-button {
        margin-top: 0px;
        padding: 10px;
        width: 70%;
    }

    .banner-button a {
        font-size: 16px;
    }

    .navbar-main {
        gap: 5px;
        padding-left: 15px;
        align-items: left;
    }

    /* about page  */
    .abt-banner-text p {
        /* text-align: justify; */
        padding: 15px;
    }

    .outlet-years {
        padding: 30px 0px 0px 0px;
    }

    .offer-bg {
        padding: 30px 15px;
    }

    .features-main {
        padding: 30px 15px;
    }

    .abt-feature-row {
        padding: 30px 0;
    }

    .about-main {
        padding: 50px 0 30px 0px;
    }

    .abt-banner-text h2 {
        font-size: 24px;
        line-height: 28px;
        margin-top: 70px;
    }

    .abt-banner-text h3 {
        font-size: 32px;
    }

    .abt-offer-text h2 {
        font-size: 24px;
        line-height: 28px;
    }

    .abt-offer-text h3 {
        font-size: 32px;
    }

    .abt-features h2 {
        font-size: 24px;
        line-height: 28px;
    }

    .abt-features h3 {
        font-size: 32px;
    }

    .offer-bg {
        background-size: contain;
    }

    .career-text h2 {
        font-size: 24px;
        line-height: 30px;
    }

    .career-text h3 {
        font-size: 32px;
    }

    .abt-offer-text p {
        width: 100%;
    }

    .abt-features p {
        width: 100%;
    }

    .container-new {
        width: 100%;
        margin: 0 auto;
    }

    .franchisee-banner {
        padding: 40px 15px;
    }


    .franchise-form-main {
        padding: 80px 15px;
    }

    .franchise-text h2 {
        font-size: 18px;
        max-width: 100%;
        margin-top: 70px;
    }

    .franchise-text h2 p {
        font-size: 18px;
        margin-bottom: 2rem;
        max-width: 100%;
    }

    .franchise-text span {
        text-align: left;
        font-size: 15px;
        margin-left: -1px;
    }

    .franchise-text p {
        width: auto;
        font-size: 15px;
        text-align: justify;
    }

    .franchise-text p span {
        font-size: 17px;
    }


    .container-franchise {
        width: 92%;
        margin: 0px auto;
    }

    /* .silverModel{
        max-width: 250px;
        margin-left: 7px;
      } */


    .franchise-box1 {
        background-color: #E3996F;
        text-align: center;
        padding: 20px;
        border-radius: 30px;
        min-height: 200px !important;
        margin-bottom: 2rem;
    }

    .silverImage {
        max-width: 100%;
    }

    .franchise-boxModel h2 {
        color: #FFB08B;
        margin-bottom: 1rem;
        text-align: center;
        font-size: 22px;
        font-family: Poppins;
        font-weight: 600;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
    }



    .franchise-bg {
        padding: 0 15px;
    }

    .journey-text h2 {
        font-size: 26px;
    }

    .journey-text h3 {
        font-size: 26px;
    }

    .success-stories h2 {
        font-size: 26px;
    }

    .success-stories h3 {
        font-size: 26px;
    }

    .success-stories {
        width: 200px;
    }

    .slick-next {
        display: none !important;
    }

    .slick-prev {
        display: none !important;
    }

    .success-stories-main {
        padding: 40px 15px;
    }

    .testimonial p {
        font-size: 14px;
    }

    .franchise-box111 img {
        margin-top: 50%;
        margin-left: -5%;
    }

    .abc {
        position: absolute;
        top: 155vh;
        width: 30px;
        z-index: 1;
    }

    .rightSide {
        position: absolute;
        top: 159vh;
        float: right;
        width: 30px;
        z-index: 1;
    }

    .faq {
        position: relative;
        top: 20vh;
        height: 1939px !important;
    }

}

@media screen and (min-width: 577px) and (max-width: 767px) {
    .popup {
        height: 40px;
        background-color: white;
        width: 40px;
        border-radius: 50%;
        position: absolute;
        cursor: pointer;
        right: 10px !important;
        ;
        bottom: 95px;
    }

    .notification {
        background-color: transparent;
        border: 1px solid gray;
        border-radius: 10px;
        width: 100px;
        height: 30px;
        margin-left: -30px !important;
        padding-top: 3px;
        margin-top: -60px;
        transform: translateX(-50%);
        font-weight: 700;
        color: white;
        font-size: 14px;
        position: absolute;
        top: 30px;
        left: 50%;
    }

    .popup-content {
        text-align: center;
        position: relative;
    }

    .popup-content img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-bottom: 0px;
        margin-top: 7px;
        margin-right: 2px;
    }

    .silverModel {
        max-width: 100%;
    }

    .banner-main {
        padding: 40px 0;
    }

    .franchise-text h2 {
        font-size: 18px;
        max-width: 100%;
        margin-top: 70px;
    }

    .franchise-box {
        min-width: 250px !important;
        margin-left: -2px;
        padding: 50px 15px 50px 15px !important;
    }

    .rightSideGetFranchise {
        height: 100px;
        position: absolute;
        top: 27vh;
    }
    .rightSideGetFranchises {
        height: 100px;
        position: absolute;
        top: 27vh;

    }
    .franchise-text h2 p {
        font-size: 18px;
        margin-bottom: 2rem;
        max-width: 100%;
    }

    
    
    .banner-text h2 {
        font-size: 32px;
        line-height: 44px;
        ;
    }

    .banner-text h2 span {
        font-size: 32px;
        line-height: 44px;
    }

    .about-home-text h3 {
        font-size: 32px;
    }

    .about-home-text h2 {
        font-size: 32px;
    }

    .about-home-text p {
        font-size: 17px;
    }

    .people-love p {
        font-size: 17px;
    }

    .people-love p span {
        font-size: 17px;
    }

    .operational-cafe p {
        font-size: 17px;
        line-height: 27px;
    }

    .operational-cafe-main {
        padding: 40px 0;
    }

    .operational-cafe h2 {
        font-size: 32px;
    }

    .operational-cafe p {
        padding: 40px 15px;
    }

    .mission-bg {
        padding: 50px 0;
    }

    .people-love {
        margin: 50px auto;
    }

    .footer-form input {
        margin-bottom: 20px;
    }

    .footer-nav {
        display: inline;
        width: 95%;
        margin: 0px auto;
    }

    .franchise-nav {
        display: inline;
        width: 100%;
        margin: 40px auto;
    }

    .footer-text h3 {
        text-align: left;
    }

    .missionbox {
        padding: 21px 9px;
    }

    /* about page  */
    .offer-bg {
        background-size: contain;
        padding: 30px 0;
    }

    .about-main {
        padding: 50px 0 30px 0px;
    }

    .features-main {
        padding: 30px 0px;
    }

    .abt-features p {
        width: 100%;
    }

    .container-franchise {
        width: 96%;
        margin: 0px auto;
    }

    .franchise-text p {
        width: auto;
    }

    .container-new {
        width: 100%;
        margin: 0 auto;
    }

    .journey-text h2 {
        font-size: 28px;
    }

    .journey-text h3 {
        font-size: 28px;
    }

    .success-stories h2 {
        font-size: 28px;
    }

    .success-stories h3 {
        font-size: 28px;
    }

    .slick-prev {
        left: 0px !important;
    }

    .slick-next {
        right: 0px !important;
    }

    .testimonial p {
        font-size: 18px;
    }

    .success-stories-main {
        padding: 40px 0;
    }

    .franchisee-banner {
        padding: 50px 15px;
    }

    .faq {
        position: relative;
        top: 10vh;
    }

}

@media screen and (min-width:768px) and (max-width: 992px) {
    .popup {
        height: 60px;
        background-color: white;
        width: 60px;
        border-radius: 50%;
        position: absolute;
        cursor: pointer;
        right: 30px !important;
        ;
        bottom: 15px;
    }

    .notification {
        background-color: transparent;
        border: 1px solid gray;
        border-radius: 10px;
        width: 100px;
        height: 30px;
        margin-left: -30px !important;
        padding-top: 3px;
        margin-top: -60px;
        transform: translateX(-50%);
        font-weight: 700;
        color: white;
        font-size: 14px;
        position: absolute;
        top: 30px;
        left: 50%;
    }

    .popup-content {
        text-align: center;
        position: relative;
    }

    .popup-content img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin-bottom: 0px;
        margin-top: 7px;
        margin-right: 2px;
    }

    .rightSideGetFranchise {
        height: 150px;
        position: absolute;
        top: 27vh;
    }
    .rightSideGetFranchises {
        height: 150px;
        position: absolute;
        top: 27vh;
    }
    .franchise-box {
        min-width: 250px !important;
        margin-left: -2px;
        padding: 50px 25px 50px 25px !important;
    }

    .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        background: #3A150C;
        padding: 0 30px;
        position: fixed;
        top: 0;
        bottom: 20%;
        width: 100%;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        z-index: 100;
    }

    .franchise-text h2 {
        font-size: 18px;
        max-width: 100%;
        margin-top: 70px;
    }

    .franchise-text h2 p {
        font-size: 18px;
        margin-bottom: 2rem;
        max-width: 100%;
    }

    .banner-text {
        width: 650px;
        margin: 63px auto;
    }

    .outlets {
        width: 100%;
    }

    .silverModel {
        max-width: 100%;
    }

    .home-about {
        padding: 30px;
    }

    .operational-cafe h2 {
        font-size: 30px;
        line-height: 47px;
    }

    .operational-cafe p {
        padding-top: 8px;
        padding-bottom: 22px;
    }

    .missionbox {
        padding: 30px;
    }

    .operational-cafe-main {
        padding: 40px 0;
    }

    .mission-bg {
        padding: 40px 0;
    }

    .footer-form input {
        font-size: 16px;
    }

    .banner-main {
        padding: 50px 0;
    }

    .footer-text h3 {
        text-align: left;
    }

    .abt-features p {
        width: 100%;
    }

    .container-franchise {
        width: 95%;
        margin: 0px auto;
    }

    .slick-prev {
        left: -41px !important;
        font-size: 20px !important;
    }

    .slick-next {
        right: -20px !important;
    }

    /* about page  */
    .offer-bg {
        background-size: contain;
        padding: 30px 0;
    }

    .about-main {
        padding: 50px 0 30px 0px;
    }

    .features-main {
        padding: 30px 0px;
    }

    .abt-offer-text p {
        width: 100%;
    }

    .abt-features p {
        width: 100%;
    }

    .container-franchise {
        width: 96%;
        margin: 0px auto;
    }


    .franchise-text h2 {
        padding: 0 1rem;
        width: 50% !important;
        font-size: 20px !important;
    }

    .franchise-text p {
        padding: 0 1rem;
        font-size: 17px !important;
        width: 335px;
        margin-top: -1rem;
    }

    .franchise-text span {
        color: #FFAB83;
        padding: 1rem 1rem;
        font-size: 17px !important;
    }

    .franchise-button {
        padding: 2rem 1rem;
        text-align: left;
        margin-top: 3rem;
    }

    .text-end img {
        width: 50%;
        margin-top: -20rem;
    }


    .franchise-box1 {
        min-height: 275px !important;
        margin-bottom: 2rem;
        margin-top: 0rem;
    }

    .container-new {
        width: 100%;
        margin: 0 auto;
    }

    .journey-text h2 {
        font-size: 28px;
    }

    .journey-text h3 {
        font-size: 28px;
    }

    .success-stories h2 {
        font-size: 28px;
    }

    .success-stories h3 {
        font-size: 28px;
    }

    .slick-prev {
        left: 0px !important;
    }

    .slick-next {
        right: 0px !important;
    }

    .testimonial p {
        font-size: 18px;
    }

    .success-stories-main {
        padding: 40px 0;
    }

    .franchisee-banner {
        padding: 50px 0 40px;
    }

    /* career  */


    .footer-main {
        margin-top: 0rem;
    }

    .career-text h2 {
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .career-text h3 {
        color: #FFAB83;
        font-family: Poppins;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
    }

    .career-text p {
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 40px 0;
    }

    .faq {
        position: relative;
        top: 120px;
    }
}

@media screen and (min-width: 992px) {
    .footer-text h3 {
        text-align: left;
    }

    .franchise-text h2 {
        font-size: 33px;
        max-width: 100%;
        margin-top: 0px;
    }

    .franchise-text h2 p {
        font-size: 35px;
        margin-bottom: 2rem;
        max-width: 100%;
    }
}

@media screen and (width: 540px) {


    .banner-text h2 {
        font-size: 29px !important;
        line-height: 34px;
    }
}








@media screen and (width: 360px) {


    .banner-text h2 {
        font-size: 20px !important;
        line-height: 34px;
    }
}

@media screen and (min-width: 768px) and (max-width:992px) {


    .button h3 {
        font-size: 18px;

    }
}

@media screen and (width: 768px) {

    .button h3 {
        font-size: 14px !important;

    }

    .abc {
        position: absolute;
        top: 80vh;
        left: -70px;
        z-index: 1;
    }

    .rightSide {
        position: absolute;
        top: 80vh;
        float: right;
        z-index: 1;
    }
}

@media screen and (width: 320px) {


    .banner-text h2 {
        font-size: 17px !important;
        line-height: 30px;
    }

    .banner-text h2 span {
        font-size: 20px !important;
        line-height: 0px !important;
    }

    .franchise-box {
        min-width: 250px !important;
        margin-left: -2px;
        padding: 50px 15px 50px 15px !important;
    }

}

@media screen and (width: 712px) {


    .banner-text h2 {
        font-size: 37px !important;
        line-height: 35px;
    }

    .banner-text h2 span {
        font-size: 30px !important;
        line-height: 50px !important;
    }
}

@media screen and (width: 480px) {
    .banner-text h2 {
        font-size: 26px !important;
        line-height: 35px;
    }

    .banner-text h2 span {
        font-size: 28px !important;
        line-height: 50px !important;
    }
}

@media screen and (min-width: 353px) and (max-width:375px) {
    .banner-text h2 {
        font-size: 19px !important;
        line-height: 35px;
    }

    .banner-text h2 span {
        font-size: 21px !important;
        line-height: 38px !important;
    }


}

@media screen and (width: 240px) {
    .navbar-bg {
        padding: 0px 0px;
    }

    .img-fluid {
        max-width: 67%;
        height: auto;
        padding: 0 0px;
    }

    .navbar-toggler-icon,
    .cross-icon,
    .burger-icon {
        transition: color 0.3s ease;
        float: right;
        margin-left: 186px;
        margin-top: -25px;
    }


}

@media screen and (width: 280px) {
    .navbar-bg {
        padding: 0px 0px !important;
    }

    .img-fluid {
        max-width: 67% !important;
        height: auto;
        padding: 12px 0px !important;
    }

    .banner-text h2 {
        font-size: 15px !important;
        line-height: 34px !important;
    }

    .banner-text h2 span {
        font-size: 17px !important;
        line-height: 30px !important;
    }

    .mission-bg {
        padding: 36px 10px !important;
    }

    .people-love {
        width: 100% !important;
        margin: 40px auto !important;
    }


}

@media screen and (width: 600px) {
    .banner-text h2 {
        font-size: 33px !important;
        line-height: 34px !important;
    }

    .banner-text h2 span {
        font-size: 36px !important;
        line-height: 56px !important;
    }

    .navbar-main {
        gap: 5px;
        padding: 15px;
        align-items: left;
    }

    .footer-text h3 {
        text-align: left;
        margin-left: -43px;
    }
}

@media screen and (min-width: 1024px) and (max-width:1280px) {
    .popup {
        height: 60px;
        background-color: white;
        width: 60px;
        border-radius: 50%;
        position: absolute;
        cursor: pointer;
        right: 30px !important;
        ;
        bottom: 15px;
    }

    .notification {
        background-color: transparent;
        border: 1px solid gray;
        border-radius: 10px;
        width: 100px;
        height: 30px;
        margin-left: -30px !important;
        padding-top: 3px;
        margin-top: -60px;
        transform: translateX(-50%);
        font-weight: 700;
        color: white;
        font-size: 14px;
        position: absolute;
        top: 30px;
        left: 50%;
    }

    .popup-content {
        text-align: center;
        position: relative;
    }

    .popup-content img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin-bottom: 0px;
        margin-top: 7px;
        margin-right: 2px;
    }

    .banner-text {
        width: 650px;
        margin: 63px auto;
    }

    .franchisee-banner {
        padding: 60px 0 3rem;
    }

    .franchise-box1 {
        min-height: 350px !important;
        margin-bottom: 2rem;
        margin-top: 0rem;
    }

    .franchise-text h2 {
        color: #FFF;
        font-family: Poppins;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        margin-left: -3rem;
        text-align: left;
    }

    .franchise-text h2 p {
        color: #FFAB83;
        font-family: Poppins;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        margin-top: .5rem;
        margin-bottom: 3rem;
        text-align: left;
    }

    .franchisee-banner-text {
        margin-left: -3rem;
    }

    .franchise-text p {
        color: #FFF;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 508px;
        text-align: left;
    }

    .franchise-text span {
        color: #FFAB83;
        font-family: Poppins;
        font-size: 20px;
        float: left;
        margin-top: -1rem;
        margin-left: -3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .franchise-button {
        padding: 40px 0;
        text-align: left;
        margin-top: 3rem;
        margin-left: -3rem;
    }

    .text-end img {
        width: 80%;
    }

    .faq {
        position: relative;
        top: 120px;
    }

}

@media screen and (min-width: 284px) and (max-width: 576px) {
    .input-group-addon {
        top: 49.8%;
        right: 21px;
        width: 11%;
        border-radius: 4px;
        height: 49.5px;
        transform: translateY(-50%);
    }

    .form input:hover {
        width: 100%;
        padding: 12px;
        margin-bottom: 20px;
        background-color: #a04323;
        color: #FFAB83;
        border-radius: 4px;
        font-size: 1rem;
        box-sizing: border-box;
    }

    .button-design {
        width: 100%;
        padding: 11px;
        border: none;
        border-radius: 4px;
        background-color: #FFAB83;
        color: #fff;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
    }

    .forgot-password {
        color: #f2f2f2;
        margin-top: 25px;
        text-decoration: none;
        font-size: 17px;
        cursor: pointer;
        transition: color 0.3s ease-in-out;
    }

    .action-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }
}

@media screen and (min-width: 224px) and (max-width: 283px) {
    .input-group-addon {
        top: 60.3%;
        right: 21px;
        width: 11%;
        border-radius: 4px;
        height: 49.5px;
        transform: translateY(-50%);
    }

    .form input:hover {
        width: 100%;
        padding: 12px;
        margin-bottom: 20px;
        background-color: #a04323;
        color: #FFAB83;
        border-radius: 4px;
        font-size: 1rem;
        box-sizing: border-box;
    }

    .button-design {
        width: 100%;
        padding: 11px;
        border: none;
        border-radius: 4px;
        background-color: #FFAB83;
        color: #fff;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
    }

    .forgot-password {
        color: #f2f2f2;
        margin-top: 25px;
        text-decoration: none;
        font-size: 17px;
        cursor: pointer;
        transition: color 0.3s ease-in-out;
    }

    .action-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }
}

@media screen and (min-width: 191px) and (max-width: 223px) {
    .input-group-addon {
        top: 56.8%;
        right: 21px;
        width: 11%;
        border-radius: 4px;
        height: 49.5px;
        transform: translateY(-50%);
    }

    .form input:hover {
        width: 100%;
        padding: 12px;
        margin-bottom: 20px;
        background-color: #a04323;
        color: #FFAB83;
        border-radius: 4px;
        font-size: 1rem;
        box-sizing: border-box;
    }

    .button-design {
        width: 100%;
        padding: 11px;
        border: none;
        border-radius: 4px;
        background-color: #FFAB83;
        color: #fff;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
    }

    .forgot-password {
        color: #f2f2f2;
        margin-top: 25px;
        text-decoration: none;
        font-size: 17px;
        cursor: pointer;
        transition: color 0.3s ease-in-out;
    }

    .action-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }
}

@media screen and (min-width: 100px) and (max-width: 190px) {
    .footer-social i {
        color: #000;
        font-size: 24px;
        background-color: #FFAB83;
        padding: 8px 8px;
        width: 65%;
        border-radius: 10px;
        cursor: pointer;
        margin: 0 13px;
    }

    .input-group-addon {
        top: 48.6%;
        right: 21px;
        width: 11%;
        border-radius: 4px;
        height: 49.5px;
        transform: translateY(-50%);
    }

    .form input:hover {
        width: 100%;
        padding: 12px;
        margin-bottom: 20px;
        background-color: #a04323;
        color: #FFAB83;
        border-radius: 4px;
        font-size: 1rem;
        box-sizing: border-box;
    }

    .button-design {
        width: 100%;
        padding: 11px;
        border: none;
        border-radius: 4px;
        background-color: #FFAB83;
        color: #fff;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
    }

    .forgot-password {
        color: #f2f2f2;
        margin-top: 25px;
        text-decoration: none;
        font-size: 17px;
        cursor: pointer;
        transition: color 0.3s ease-in-out;
    }

    .action-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }

    .card p {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 2px;
        text-align: center;
        margin-bottom: 3rem;
        color: #FFAB83;
    }
}

@media screen and (min-width: 1400px) and (max-width:1440px) {

    .banner-text {
        width: 650px;
        margin: 63px auto;
    }


    .franchisee-banner {
        padding: 60px 0 3rem;
    }

    .faq {
        position: relative;
        top: 180px;
        height: 1450px;
    }


    .franchise-box1 {
        height: 27vh;
        margin-bottom: 2rem;
        margin-top: 0rem;
    }

    .franchise-text h2 {
        color: #FFF;
        font-family: Poppins;
        font-size: 37px;
        font-style: normal;
        font-weight: 400;
        margin-left: -3rem;
        text-align: left;
    }

    .franchise-text h2 p {
        color: #FFAB83;
        font-family: Poppins;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        margin-top: .5rem;
        margin-bottom: 3rem;
        text-align: left;
    }

    .franchisee-banner-text {
        margin-left: -3rem;
    }

    .franchise-text p {
        color: #FFF;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 508px;
        text-align: left;
    }

    .franchise-text span {
        color: #FFAB83;
        font-family: Poppins;
        font-size: 20px;
        float: left;
        margin-top: -1rem;
        margin-left: -3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .franchise-button {
        padding: 40px 0;
        text-align: left;
        margin-top: 3rem;
        margin-left: -3rem;
    }

    .text-end img {
        width: 80%;
    }

}

@media screen and (min-width: 993px) and (max-width:1023px) {
    .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50pximportant;
        background: #3A150C;
        padding: 0 30px;
        position: fixed;
        top: 0;
        bottom: 20%;
        width: 100%;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        z-index: 100;
    }

    .banner-text {
        width: 650px;
        margin: 63px auto;
    }


}